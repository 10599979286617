import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useState, useEffect, useRef } from 'react';

function BaseNavbar() {
  const [isClicked, setIsClicked] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const scrollTimeoutRef = useRef(null);

  const sections = ['home', 'about', 'menu', 'contact'];

  const handleNavbarClick = (idx) => {
    setIsClicked(idx);
  };

  const handleScroll = () => {
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }

    scrollTimeoutRef.current = setTimeout(() => {
      const scrollPosition = window.scrollY;

      sections.forEach((section, index) => {
        const sectionElement = document.getElementById(section);
        if (sectionElement) {
          const sectionTop = sectionElement.offsetTop;
          const sectionHeight = sectionElement.offsetHeight;

          if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
            setIsClicked(index);
          }
        }
      });
    }, 100); // Adjust debounce timing as needed
  };

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);

    // Initialize Google Translate
    const script = document.createElement('script');
    script.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    document.body.appendChild(script);

    window.googleTranslateElementInit = function () {
      new window.google.translate.TranslateElement({
        pageLanguage: 'nl,en',
        autoDisplay: 'true',
        includedLanguages: 'en,nl',
        layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL
      }, 'google_translate_element');
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, []);

  return (
    <Navbar expand="lg" className={`bg-body-tertiary ${isMobile ? 'navbar-light' : 'navbar-custom '}`} fixed="top">
      <Container>
        <Navbar.Brand href="#home"><h1 className='logo-title'>Ishaan Pure Veg</h1></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {sections.map((section, index) => (
              <Nav.Link key={section} onClick={() => handleNavbarClick(index)}>
                <AnchorLink href={`#${section}`} id={isClicked === index ? "nav-txt-active" : "nav-txt"}>
                  <span className='innerNavTextyy'>{section.toUpperCase()}</span>
                </AnchorLink>
              </Nav.Link>
            ))}
            <div id="google_translate_element"></div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BaseNavbar;
