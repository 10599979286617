import { useState, useEffect } from 'react';

function Menu() {
    const [activeTab, setActiveTab] = useState("tab-starter");
    const [starterData, setStarterData] = useState([]);
    const [mainCourseData, setMainCourseData] = useState([]);
    const [dessertData, setDessertData] = useState([]);
    const [traditionalData, setTraditionalData] = useState([]);
    const [indianChineseData, setIndianChineseData] = useState([]);
    const [southIndianData, setSoundIndianData] = useState([]);
    const [error, setError] = useState(null);
    const [isMobile, setisMobile] = useState(true);

    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    const fetchData = async (categoryId) => {
        const token = localStorage.getItem('accessToken'); // Assuming the token is stored in local storage
        try {
            const response = await fetch(`https://ishaanpureveg.nl/api/dishbycategory/${categoryId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accessToken': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data;
        } catch (err) {
            setError(err.message);
            console.error('Fetch error:', err);
        }
    };

    useEffect(() => { 
        setisMobile(window.innerWidth <= 768);
        const fetchAllData = async () => {
            const starter = await fetchData(5);
            const mainCourse = await fetchData(3);
            const dessert = await fetchData(1);
            const traditional = await fetchData(6);
            const indianChinese = await fetchData(2);
            const southIndian = await fetchData(4);

            if (starter) setStarterData(starter);
            if (mainCourse) setMainCourseData(mainCourse);
            if (dessert) setDessertData(dessert);
            if (traditional) setTraditionalData(traditional);
            if (indianChinese) setIndianChineseData(indianChinese);
            if (southIndian) setSoundIndianData(southIndian);
        };

        fetchAllData();
    }, []);

    return (
        <div className="menu-main-container" id="menu">
            <h1 className="orange-heading" style={{ textAlign: 'center' }}>Food Menu</h1>
            <div className="menu-inner-boundary-container">
                <div className="menu-category-main-container">
                    <div className="menu-sub-category-container">
                        <ul className="nav nav-pills d-inline-flex justify-content-center border-bottom mb-4">
                            {[
                                { id: 'tab-starter', icon: 'fa-coffee', label: 'Starter' },
                                { id: 'tab-main', icon: 'fa-pepper-hot', label: 'Course' },
                                { id: 'tab-chinese', icon: 'fa-utensils', label: 'Chinese' },
                                { id: 'tab-dessert', icon: 'fa-cookie-bite', label: 'Extras' },
                                { id: 'tab-traditional', icon: 'fa-fire', label: 'Tradition' },
                                { id: 'tab-southIndian', icon: 'fa-heart', label: 'Indian' },
                            ].map((tab) => (
                                <li className="nav-item custom-width" onClick={() => handleTabChange(tab.id)} key={tab.id}>
                                    <p className={`d-flex align-items-center text-start mx-3 pb-3 ${activeTab === tab.id ? 'item-container-active' : 'item-container'}`} data-bs-toggle="pill" href={`#${tab.id}`}>
                                        <i className={`fa-solid ${tab.icon} fa-2x`} id='hey'></i>
                                        <div className="ps-3" id='hey'>
                                            <small className="text-body-own">
                                                {tab.id === 'tab-southIndian' ? 'South' : ''}
                                                {tab.id !== 'tab-southIndian' && tab.id !== 'tab-dessert' && tab.id !== 'tab-main' ? ' Indian' : ''}
                                                {tab.id === 'tab-dessert' ? 'Dessert' : ''}
                                                {tab.id === 'tab-main' ? 'Main ' : ''}
                                            </small>
                                            <h6 className="mt-n1 mb-0" style={{ fontWeight: 'bolder'}}>{tab.label}</h6>
                                        </div>
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                
                <div className="tab-content">
                    {activeTab === 'tab-starter' && (
                        <div className="row g-4">
                            {starterData.map((item, index) => (
                                <div className="col-lg-6 col-md-6 col-12 pe-4" key={item.id}>
                                    <div className="d-flex align-items-center item-content-padding">
                                        <div className={`w-100 d-flex flex-column text-start ${isMobile ? 'ps-6' : 'ps-5 '}`}>
                                            <h5 className="d-flex justify-content-between border-bottom pb-2">
                                                <span>{index + 1}. {item.name}</span> {/* Change here */}
                                                <span className="primary-own">€{item.price}</span>
                                            </h5>
                                            <small className="fst-italic pb-4">{item.description}</small>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {activeTab === 'tab-main' && (
                        <div className="row g-4">
                            {mainCourseData.map((item, index) => (
                                <div className="col-lg-6 col-md-6 col-12 pe-4" key={item.id}>
                                    <div className="d-flex align-items-center item-content-padding">
                                        <div className={`w-100 d-flex flex-column text-start ${isMobile ? 'ps-6' : 'ps-5 '}`}>
                                            <h5 className="d-flex justify-content-between border-bottom pb-2">
                                                <span>{index + 1}. {item.name}</span> {/* Change here */}
                                                <span className="primary-own">€{item.price}</span>
                                            </h5>
                                            <small className="fst-italic pb-4">{item.description}</small>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {activeTab === 'tab-dessert' && (
                        <div className="row g-4">
                            {dessertData.map((item, index) => (
                                <div className="col-lg-6 col-md-6 col-12 pe-4" key={item.id}>
                                    <div className="d-flex align-items-center item-content-padding">
                                        <div className={`w-100 d-flex flex-column text-start ${isMobile ? 'ps-6' : 'ps-5 '}`}>
                                            <h5 className="d-flex justify-content-between border-bottom pb-2">
                                                <span>{index + 1}. {item.name}</span> {/* Change here */}
                                                <span className="primary-own">€{item.price}</span>
                                            </h5>
                                            <small className="fst-italic">{item.description}</small>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {activeTab === 'tab-traditional' && (
                        <div className="row g-4">
                            {traditionalData.map((item, index) => (
                                <div className="col-lg-6 col-md-6 col-12 pe-4" key={item.id}>
                                    <div className="d-flex align-items-center item-content-padding">
                                        <div className={`w-100 d-flex flex-column text-start ${isMobile ? 'ps-6' : 'ps-5 '}`}>
                                            <h5 className="d-flex justify-content-between border-bottom pb-2">
                                                <span>{index + 1}. {item.name}</span> {/* Change here */}
                                                <span className="primary-own">€{item.price}</span>
                                            </h5>
                                            <small className="fst-italic">{item.description}</small>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {activeTab === 'tab-chinese' && (
                        <div className="row g-4">
                            {indianChineseData.map((item, index) => (
                                <div className="col-lg-6 col-md-6 col-12 pe-4" key={item.id}>
                                    <div className="d-flex align-items-center item-content-padding">
                                        <div className={`w-100 d-flex flex-column text-start ${isMobile ? 'ps-6' : 'ps-5 '}`}>
                                            <h5 className="d-flex justify-content-between border-bottom pb-2">
                                                <span>{index + 1}. {item.name}</span> {/* Change here */}
                                                <span className="primary-own">€{item.price}</span>
                                            </h5>
                                            <small className="fst-italic">{item.description}</small>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {activeTab === 'tab-southIndian' && (
                        <div className="row g-4">
                            {southIndianData.map((item, index) => (
                                <div className="col-lg-6 col-md-6 col-12 pe-4" key={item.id}>
                                    <div className="d-flex align-items-center item-content-padding">
                                        <div className={`w-100 d-flex flex-column text-start ${isMobile ? 'ps-6' : 'ps-5 '}`}>
                                            <h5 className="d-flex justify-content-between border-bottom pb-2">
                                                <span>{index + 1}. {item.name}</span> {/* Change here */}
                                                <span className="primary-own">€{item.price}</span>
                                            </h5>
                                            <small className="fst-italic">{item.description}</small>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Menu;
