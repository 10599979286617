import React, { useEffect, useState } from 'react'; 
import DishTable from './DishTable'; // Import DishTable
import '../scss/styles.scss';
import { useNavigate } from "react-router-dom"; 
import AdminNavbar from './AdminNavbar';

function AdminHome() {
  const navigate = useNavigate();
  const handleMenuClick = (id)=>{
    navigate(`/admin/dishbycategory/${id}`)
  }
  useEffect(() => { 
    const isAuthenticated = localStorage.getItem('gibrish');
    if (!isAuthenticated) {
      alert("Please Login First")
      navigate('/auth/admin'); 
    }
  }, [navigate]);
  return (
    <div>
<AdminNavbar/>
    <div className="main-admin-content">
    <div className='admin-main-container'> 
          <div className="category-chose-main text-center mt-4">
            <h3 className="purple-txt">Choose Dish Category</h3>
            <div className="category-divs-main">
            <button className="cat btn btn-dark"onClick={()=>handleMenuClick(1)}>Desserts & Extras</button>
            <button className="cat btn btn-dark"onClick={()=>handleMenuClick(2)}>Indian & Chinese</button>
            <button className="cat btn btn-dark"onClick={()=>handleMenuClick(3)}>Main Course</button>
            <button className="cat btn btn-dark"onClick={()=>handleMenuClick(4)}>South Indian</button>
            <button className="cat btn btn-dark"onClick={()=>handleMenuClick(5)}>Starter</button>
            <button className="cat btn btn-dark"onClick={()=>handleMenuClick(6)}>Traditional</button> 
            </div>
             </div>
        </div>
    
    </div>
    
        </div>
  );
}

export default AdminHome;
