import React, { useState } from 'react';
import axios from 'axios';

const Footer = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isMessageSent, setIsMessageSent] = useState(false);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); 
        
        try {
            setIsLoading(true);
            const requestData = new URLSearchParams();
            requestData.append('email', email);
            
            setEmail('');  
            await axios.post('https://script.google.com/macros/s/AKfycbx8T8M2aTWalTE19jZBgop6O7lRDkP-0Bs-CdQWZWeOcIXt3z_fRxj7YbWwxCuCSSj3/exec', requestData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });
            setIsMessageSent(true); // Show success message  
            setTimeout(() => setIsMessageSent(false), 1000); // Hide message after 2 seconds
        } catch (e) {
            console.error('Error sending email:', e);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <footer className="footer-section">
            <div className="container">
                <div className="footer-content pt-5 pb-5">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 mb-50">
                            <div className="footer-widget">
                                <div className="footer-logo">
                                    <h1>Ishaan Pure Veg</h1>
                                </div>
                                <div className="footer-text">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                            <div className="footer-widget">
                                <div className="footer-widget-heading">
                                    <h3 className='hery'>Useful Links</h3>
                                </div>
                                <ul>
                                    <li><a href="#">Home</a></li>
                                    <li><a href="#">About</a></li>
                                    <li><a href="#">Menu</a></li> 
                                    <li><a href="#">Contact</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                            <div className="footer-widget">
                                <div className="footer-widget-heading">
                                    <h3>Subscribe</h3>
                                </div>
                                <div className="footer-text mb-25">
                                    <p>Don’t miss to subscribe to our new feeds, kindly fill the form below.</p>
                                </div>
                                <div className="subscribe-form">
                                    <form onSubmit={handleSubmit}>
                                        <input 
                                            type="email" 
                                            placeholder="Email Address" 
                                            value={email}
                                            onChange={handleEmailChange}
                                            required
                                        />
                                        <button type="submit" disabled={isLoading}>
                                            {isLoading ? 'Sending...' : 'Subscribe'}
                                        </button>
                                    </form>
                                    {isMessageSent && <p>Email sent successfully!</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-area">
                <div className="container">
                    <div className="row">
                        <div>
                            <div className="copyright-text">
                                <p className='hery1'>Website Made With 💛 By  &copy; 2024, All Right Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
