 

function KeyCard() {
  return (
   <div className='key-container-main'>
    <div className="container-1-key">
    <i class="fa-solid fa-user key-card-icon"></i>
    <h1 className='key-head'>Master Chefs</h1>
    <p className='key-para'>Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita amet diam</p>
    </div>
    <div className="container-1-key">
    <i class="fa-solid fa-utensils key-card-icon"></i>
    <h1 className='key-head'>Quality Food</h1>
    <p className='key-para'>Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita amet diam</p>
    </div>
    <div className="container-1-key">
    <i class="fa-solid fa-cart-shopping key-card-icon"></i>
    <h1 className='key-head'>Online Order</h1>
    <p className='key-para'>Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita amet diam</p>
    </div>
    <div className="container-1-key">
    <i class="fa-solid fa-headset key-card-icon"></i>
    <h1 className='key-head'>24/7 Service</h1>
    <p className='key-para'>Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita amet diam</p>
    </div>
   </div>
  );
}

export default KeyCard;

