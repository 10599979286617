import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './HomePage/Home' 
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './AdminDashboard/Login';
import { BrowserRouter, Route, Routes } from 'react-router-dom'; 
import AdminHome from './AdminDashboard/AdminHome';  
import AdminNavbar from './AdminDashboard/AdminNavbar';
import DishTable from './AdminDashboard/DishTable';
import Footer from './Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <Routes>
          <Route path="/auth/admin" element={<Login />} /> 
          <Route path="/admin/dashboard" element={<AdminHome />} />  
          <Route path="/admin/dishbycategory/:categoryId" element={<DishTable />} /> 
          <Route path="/footer" element={<Footer />} /> 
        <Route path="/" element={<Home />}>
        </Route>
      </Routes>
    </BrowserRouter>
);  
