import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';


function Login() {
  // State for userid, password, remember checkbox, and error message
  const [userid, setUserid] = useState('');
  const [password, setPassword] = useState('');
  const [showPass, setshowPass] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // State for error message
  const navigate = useNavigate();

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Prepare the data to be sent
    const loginData = {
      userid, // Using userid instead of username
      password,
    };

    try {
      const response = await fetch('https://ishaanpureveg.nl/api/auth/admin/login', { // Use the specified endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      });

      if (response.ok) {
        const data = await response.json(); 
        localStorage.setItem('gibrish', data.accessToken);
        navigate('/admin/dashboard');
      } else {
        const errorData = await response.json();
        // Set the error message
        setErrorMessage(errorData.message || 'Login failed. Please try again.');
        console.error('Login failed:', errorData.message);

        // Clear the error message after 5 seconds
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred. Please try again.');
      console.error('Error:', error);

      // Clear the error message after 5 seconds
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
    }
  };

  return (
    <div className="container admin-auth-main" style={{ fontWeight: 'bold' }}>
      <div className="row justify-content-center mt-5">
        <div className="col-lg-4 col-md-6 col-sm-6">
          <div className="card" style={{ color: '#000000' }}>
            <div className="card-title text-center">
              <h2 className="p-3 text-primary" style={{ fontWeight: 'bold' }}>Login</h2>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label htmlFor="userid" className="form-label">User ID</label>
                  <input 
                    type="text" 
                    required
                    className="form-control input-login" 
                    id="userid" 
                    value={userid} 
                    onChange={(e) => setUserid(e.target.value)} 
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="password" className="form-label">Password</label>
                  <input 
                    type={showPass?'text':'password'}
                    className="form-control input-login" 
                    id="password" 
                    required
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                  />
                </div>
                <div className="mb-4">
                  <input 
                    type="checkbox" 
                    className="form-check-input input-login " 
                    id="showPass" 
                    checked={showPass} 
                    onChange={(e) => setshowPass(!showPass)} 
                  />
                  <label htmlFor="remember" className="form-label here">Show Password</label>
                </div>
                {errorMessage && ( // Render error message if it exists
                  <div className="mb-4 text-danger">
                    <label className="form-label">{errorMessage}</label>
                  </div>
                )}
                <div className="d-grid">
                  <button type="submit" className="btn btn-primary">Login</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
