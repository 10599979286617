import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom"; 
import AdminHome from "./AdminHome";

function AdminNavbar() {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(true);  
 
    const toggleSidebar = () => { 
    setIsCollapsed(!isCollapsed);  
  };

  const handleLogout =()=>{
    localStorage.removeItem('gibrish');
    navigate("/auth/admin")

  }
  

  return (
      <div>
            <div className="hamburder-content">

      {!isCollapsed && ( 
          <div className='left-sidebar'>
          <div>
            <div className="brand-logo d-flex align-items-center justify-content-between">
              <button className="btn" style={{ color: '#007bff', fontSize: '23px' }}>
                Admin Panel
              </button>
              <div id="sidebarCollapse" onClick={toggleSidebar}>
                <h5 style={{ fontWeight: 'bolder', color: 'red', cursor: 'pointer' ,fontSize:'21px'}} className="hamburger">X</h5>
              </div>
            </div>

            <nav className="sidebar-nav scroll-sidebar"  data-simplebar="">
              <ul id="sidebarnav" style={{paddingLeft:'0'}}>
                <li className="nav-small-cap">
                  <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                  <span className="hide-menu"  >ADMIN Home</span>
                </li>
                <li className="sidebar-item"  >
                  <p className="sidebar-link" onClick={()=>navigate('/admin/dashboard')} expanded="false">
                    <span>
                      <i className="ti ti-layout-dashboard"></i>
                    </span>
                    <span className="hide-menu">Dashboard</span>
                  </p>
                </li>
                <li className="nav-small-cap">
                  <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                  <span className="hide-menu">USER PAGES</span>
                </li>
                <li className="sidebar-item"  >
                  <p className="sidebar-link" onClick={()=>navigate('/')} aria-expanded="false">
                    <span>
                      <i className="ti ti-article"></i>
                    </span>
                    <span className="hide-menu">Home</span>
                  </p>
                </li>
                <li className="sidebar-item"  >
                  <p className="sidebar-link" onClick={()=>navigate('/')} aria-expanded="false">
                    <span>
                      <i className="ti ti-alert-circle"></i>
                    </span>
                    <span className="hide-menu">About</span>
                  </p>
                </li>
                <li className="sidebar-item"  >
                  <p className="sidebar-link" onClick={()=>navigate('/')} aria-expanded="false">
                    <span>
                      <i className="ti ti-cards"></i>
                    </span>
                    <span className="hide-menu">Menu</span>
                  </p>
                </li>
                <li className="sidebar-item"  >
                  <p className="sidebar-link" onClick={()=>navigate('/')} aria-expanded="false">
                    <span>
                      <i className="ti ti-file-description"></i>
                    </span>
                    <span className="hide-menu">Contact</span>
                  </p>
                </li>
                <li className="nav-small-cap">
                  <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                  <span className="hide-menu">AUTH</span>
                </li>
                <li className="sidebar-item">
                  <p className="sidebar-link"  onClick={handleLogout} aria-expanded="false">
                    <span>
                      <i className="ti ti-login" ></i>
                    </span>
                    <span className="hide-menu" >Logout</span>
                  </p>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}
          </div>
      <div className="hamburger-div"> 

      {isCollapsed && ( 
          <div onClick={toggleSidebar} className="hamburger ">
          <button className="btn" style={{ color: '#007bff', fontSize: '23px' }}>
          <i class="fa-solid fa-bars" style={{ color: '#007bff', fontSize: '23px' }}></i>
          </button>
          
        </div>
      )}
      <div>

      <h1 className={`navbar-greets ${isCollapsed ? 'navbar-greets-clicked' : ''}`}>
  Hello, Mr. Ravindra
</h1>
  </div>
      </div> 
    </div>
      
  );
}

export default AdminNavbar;
