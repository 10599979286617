import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Lottie from 'lottie-react';
import sentMessage from '../src/assets/sentMessage.json';

const Contact = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isMessageSent, setIsMessageSent] = useState(false);
    const [isMobile, setisMobile] = useState(true);
    
    const updateMessageSent = () => {
        setIsMessageSent(true);
        setTimeout(() => {
            setIsMessageSent(false); 
        }, 2000);
      };
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission
        
        try {
            
            // Get values from input fields
            const name = event.target.name.value;
            const email = event.target.email.value;
            const subject = event.target.subject.value;
            const message = event.target.message.value;
            
            const requestData = new URLSearchParams();
            requestData.append('name', name);
            requestData.append('email', email);
            requestData.append('subject', subject);
            requestData.append('message', message);
            
            setIsLoading(true);
            await axios.post('https://script.google.com/macros/s/AKfycbx8T8M2aTWalTE19jZBgop6O7lRDkP-0Bs-CdQWZWeOcIXt3z_fRxj7YbWwxCuCSSj3/exec', requestData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });
            event.target.reset();
            setIsLoading(false);
            updateMessageSent();
        } catch (e) {
            event.target.reset();
            setIsLoading(false);
            updateMessageSent();
        }

    };
    useEffect(() => { 
        setisMobile(window.innerWidth<=768) 
    }, []);
    return (
        <div className="container-xxl py-5" id="contact">
            <div className="container">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h5 className="section-title ff-secondary text-center  fw-normal orange-heading">Contact Us</h5>
                    <h1 className="mb-5">Contact For Any Query</h1>
                </div>
                <div className="row g-4">
                    <div className="col-12">
                        <div className="row gy-4">
                            <div className="col-md-4">
                                <h5 className="section-title ff-secondary fw-bolder text-start ">Address</h5>
                                <p><i className="fa fa-envelope-open me-2" style={{}}></i>Ishaan Restraunt</p>
                            </div>
                            <div className="col-md-4">
                                <h5 className="section-title ff-secondary fw-bolder text-start ">Email</h5>
                                <p><i className="fa fa-envelope-open  me-2" style={{}}></i>info@ishaan.nl</p>
                            </div>
                            <div className="col-md-4">
                                <h5 className="section-title ff-secondary fw-bolder text-start ">Phone</h5>
                                <p><i className="fa fa-envelope-open  me-2" style={{}}></i>000-000-000</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 py-2 web-view">
                        <h1 className='get-in-touch'>Get in touch</h1>
                        <p className='contact-container'>We’re excited to explore new opportunities. If you’re interested in collaborating with us, please reach out through any of the following methods.</p>
                        <div className='contact-details-container py-3'>
                            <div className="contact-inner-container">
                                <p className='timing-head '>Our Service Timing:-</p>
                                <p className='timing-first-text'>Monday - Friday <span className='timing-text'>08AM to 11PM</span></p>
                                <hr />
                                <p className='timing-first-text'>Saturday<span className='timing-text2'>08AM to 11PM</span></p>
                                <hr />
                                <p className='timing-first-text'>Sunday <span className='timing-text2'>08AM to 11PM</span></p>
                                <hr />

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 py-2 mobile-view">
                        <div className='contact-details-container py-3'>
                            <div className="contact-inner-container">
                                <p className='timing-head '>Our Service Timing:-</p>
                                <p className='timing-first-text'>Monday - Friday <span className='timing-text'>08AM to 11PM</span></p>
                                <hr />
                                <p className='timing-first-text'>Saturday<span className='timing-text2'>08AM to 11PM</span></p>
                                <hr />
                                <p className='timing-first-text'>Sunday <span className='timing-text2'>08AM to 11PM</span></p>
                                <hr />

                            </div>
                        <h1 className='get-in-touch'>Get in touch</h1>
                        <p className='contact-container'>We’re excited to explore new opportunities. If you’re interested in collaborating with us, please reach out through any of the following methods.</p>
                        </div>
                    </div>
                    {isMessageSent ?
                        <div className='loti-container col-md-6'>
                            <Lottie
                                animationData={sentMessage}
                                loop={true}
                                autoplay={true}
                                className='loti-width'
                            />
                            <p className='primary-own ' style={{ textAlign: 'center' }}>Message Sent</p>
                        </div>
                        : 
                        <div className={`col-md-6  ${isMobile ? '' : 'py-5 '}`} >
                            <div className="wow fadeInUp" data-wow-delay="0.2s">
                                <form onSubmit={handleSubmit}>
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" name="name" placeholder="Your Name" required />
                                                <label htmlFor="name">Your Name</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="email" className="form-control" name="email" placeholder="Your Email" required />
                                                <label htmlFor="email">Your Email</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" name="subject" placeholder="Subject" required />
                                                <label htmlFor="subject">Subject</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <textarea className="form-control" name="message" placeholder="Leave a message here" style={{ height: '150px' }} required></textarea>
                                                <label htmlFor="message">Message</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-primary w-100 py-3" type="submit" style={{ backgroundColor: 'orange', border: 0, fontSize: 'large', color: '#000' }}>
                                                {isLoading ? (
                                                    <>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 150" width="40" height="40">
                                                            <path fill="none" stroke="#000000" strokeWidth="15" strokeLinecap="round" strokeDasharray="300 385" strokeDashoffset="0" d="M275 75c0 31-27 50-50 50-58 0-92-100-150-100-28 0-50 22-50 50s23 50 50 50c58 0 92-100 150-100 24 0 50 19 50 50Z">
                                                                <animate attributeName="stroke-dashoffset" calcMode="spline" dur="2" values="685;-685" keySplines="0 0 1 1" repeatCount="indefinite"></animate>
                                                            </path>
                                                        </svg>
                                                        Sending Message
                                                    </>
                                                ) : (
                                                    <>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="20" height="20">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                                                        </svg>
                                                        Send Message
                                                    </>
                                                )}


                                            </button>
                                        </div>

                                        <div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Contact;
