import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';  
import { Modal, Button } from 'react-bootstrap';
import AdminNavbar from './AdminNavbar';
import { useNavigate } from 'react-router-dom';

function DishTable() {
  const { categoryId } = useParams(); // Get categoryId from the route
  const [dishes, setDishes] = useState([]);
  const [editDish, setEditDish] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => { 
    const isAuthenticated = localStorage.getItem('gibrish');
    if (!isAuthenticated) {
      alert("Please Login First")
      
      navigate('/auth/admin'); 
    }
  }, [navigate]);
  useEffect(() => {
    const fetchDishes = async () => {
      try {
        const accessToken = localStorage.getItem('gibrish');
        const response = await fetch(`https://ishaanpureveg.nl/api/dishbycategory/${categoryId}`, {
          headers: {
            'accessToken': `Bearer ${accessToken}`,
          },
        });
        if (!response.ok) {
          alert("Something went wrong. Please retry")
        }
        const data = await response.json();
        setDishes(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDishes();
  }, [categoryId]);

  const handleUpdateDish = async (updatedDish) => {
    try {
      const accessToken = localStorage.getItem('gibrish');
      const response = await fetch(`https://ishaanpureveg.nl/api/dishbycategory/${categoryId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'accessToken': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(updatedDish),
      });

      if (!response.ok) {
        alert("Something went wrong. Please re-login")
      }

      const data = await response.json();
      setDishes(prevDishes => prevDishes.map(dish => (dish.id === data.dish.id ? data.dish : dish)));
      setShowModal(false);
      setEditDish(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditClick = (dish) => {
    setEditDish(dish);
    setShowModal(true);
  };

  const handleChange = (e) => {
    setEditDish({
      ...editDish,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddNewDish = () => {
    const newDish = {  
      id: null,
      categoryId,
      name: '',
      description: '',
      price: ''
    };
    setEditDish(newDish);
    setShowModal(true);
  };

  const handleSaveNewDish = async () => {
    try {
      const accessToken = localStorage.getItem('gibrish');
      const response = await fetch(`https://ishaanpureveg.nl/api/dishbycategory/${categoryId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accessToken': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(editDish),
      });

      if (!response.ok) {
        alert("Something went wrong. Please re-login")

      }

      const data = await response.json();
      setDishes([...dishes, data.dish]);
      setShowModal(false);
      setEditDish(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteDish = async (dishId) => {
    try {
      const isConfirmed = window.confirm("Do you really want to delete this dish?");
      if(isConfirmed){
      const accessToken = localStorage.getItem('gibrish');
      const response = await fetch(`https://ishaanpureveg.nl/api/dishbycategory/${categoryId}/${dishId}`, {
        method: 'DELETE',
        headers: {
          'accessToken': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        alert("Something went wrong. Please re-login")

      }
      setDishes(prevDishes => prevDishes.filter(dish => dish.id !== dishId));
    }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <AdminNavbar/>
    <div className="container-lg dish-main-container"> 
      <div className="table-responsive">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="row">
              <div className="col-sm-8">
                <h2>Dish Category: <b>{dishes.length > 0 ? dishes[0].Category : "N/A"}</b></h2>
              </div>
              <div className="col-sm-4">
                <button type="button" className="btn btn-success add-new" onClick={handleAddNewDish}>
                  <i className="fa fa-plus"></i> Add New
                </button>
              </div>
            </div>
          </div>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Dish Name</th>
                  <th>Dish Description</th>
                  <th>Price</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {dishes.map((dish) => (
                  <tr key={dish.id}>
                    <td>{dish.name}</td>
                    <td>{dish.description.length > 25 ? `${dish.description.slice(0, 25)}...` : dish.description}</td>
                    <td>{dish.price}</td>
                    <td>
                      <a className="edit" title="Edit" onClick={() => handleEditClick(dish)}>
                        <i className="fa-solid fa-pen" style={{paddingRight:'15px'}}></i>
                      </a> 
                      <a 
                        className="delete" 
                        title="Delete" 
                        onClick={() => handleDeleteDish(dish.id)}
                      >
                        <i className="fa-solid fa-trash"></i>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {/* Modal for Adding/Editing Dishes */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editDish && editDish.id ? 'Edit Dish' : 'Add New Dish'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label htmlFor="dishName" className="form-label">Dish Name</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={editDish ? editDish.name : ''}
              onChange={handleChange}
              />
          </div>
          <div className="mb-3">
            <label htmlFor="dishDescription" className="form-label">Dish Description</label>
            <input
              type="text"
              className="form-control"
              name="description"
              value={editDish ? editDish.description : ''}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="dishPrice" className="form-label">Price</label>
            <input
              type="text"
              className="form-control"
              name="price"
              value={editDish ? editDish.price : ''}
              onChange={handleChange}
              />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => {
            if (editDish && editDish.id) {
              handleUpdateDish(editDish);
            } else {
              handleSaveNewDish();
            }
          }}>
            {editDish && editDish.id ? 'Save Changes' : 'Add Dish'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
            </div>
  );
}

export default DishTable;
